<script setup lang="ts">
import CheckCircleIcon from "@/assets/icons/check-circle-solid.svg?component"
import ErrorCircleIcon from "@/assets/icons/error-circle-solid.svg?component"
import WarningCircleIcon from "@/assets/icons/warning-circle-solid.svg?component"

interface Props {
  closable?: boolean
  size: "small" | "medium"
  title?: string
  type: "info" | "success" | "warn" | "error"
}

const props = withDefaults(defineProps<Props>(), {
  closable: false,
  size: "medium",
  title: undefined,
  type: "info",
})

const textColorClasses = {
  success: "text-emerald-700",
  warn: "text-amber-700",
  error: "text-red-700",
  info: "text-purple-700",
}

const backgroundClasses = computed(() => {
  const type = props.type
  if (type === "success") return "bg-emerald-50 border-emerald-500"
  if (type === "warn") return "bg-amber-50 border-amber-500"
  if (type === "error") return "bg-red-50 border-red-500"
  return "bg-purple-50 border-purple-500" // info
})

const bodyClasses = computed(() => {
  return props.size === "small" ? "px-4 py-2" : "p-4"
})

const closeButtonClasses = computed(() => {
  const type = props.type
  if (type === "success") return "focus:ring-emerald-500"
  if (type === "warn") return "focus:ring-red-500"
  if (type === "error") return "focus:ring-emerald-500"
  return "focus:ring-indigo-500" // info
})

const textClasses = computed(() => {
  return [
    props.size === "small" ? "text-xs" : "text-sm",
    textColorClasses[props.type],
  ].join(" ")
})

const titleColorClass = computed(() => {
  const type = props.type
  if (type === "success") return "text-emerald-900"
  if (type === "warn") return "text-amber-900"
  if (type === "error") return "text-red-900"
  return "text-purple-900" // info
})

const isVisible = ref(true)

function closeNotification() {
  isVisible.value = false
}

function isType(type: string): boolean {
  return props.type === type
}
</script>

<template>
  <div v-if="isVisible" :class="backgroundClasses" class="rounded border">
    <div :class="bodyClasses">
      <div
        v-if="props.title"
        class="flex items-center"
        :class="titleColorClass"
      >
        <div class="flex-shrink-0">
          <CheckCircleIcon
            v-if="isType('success')"
            class="w-5 h-5 text-emerald-500 mr-2"
            aria-hidden="true"
          />
          <WarningCircleIcon
            v-else-if="isType('warn')"
            class="w-5 h-5 text-amber-500 mr-2"
            aria-hidden="true"
          />
          <ErrorCircleIcon
            v-else-if="isType('error')"
            class="w-5 h-5 text-red-500 mr-2"
            aria-hidden="true"
          />
        </div>

        <div v-if="props.title" class="w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium">
            {{ props.title }}
          </p>
        </div>

        <div v-if="closable" class="ml-4 flex flex-shrink-0">
          <button
            type="button"
            :class="closeButtonClasses"
            class="inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
            @click="closeNotification"
          >
            <span class="sr-only">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M5.757 4.343a1 1 0 0 0-1.414 1.414L8.585 10l-4.242 4.243a1 1 0 1 0 1.414 1.414L10 11.414l4.242 4.243a1 1 0 0 0 1.414-1.414L11.414 10l4.243-4.243a1 1 0 0 0-1.415-1.414L10 8.586 5.757 4.343Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div :class="textClasses">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
